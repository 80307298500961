import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          {/* <div className="logo">
            <a href=".">LOGO</a>
          </div> */}
          {/* <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://instagram.com" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
          </div> */}
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Coming Soon</h1>
            <p>Website coming soon. Please check back to know more.</p>
          </div>
          {/* <a href="mailto:arisantoso1@gmail.com">
            <div className="cta">Send us an email</div>
          </a> */}
        </div>
        {/* <div className="footer">
          <span>FOOTER</span>
        </div> */}
      </div>
    );
  }
}

export default App;